<template> 
    <Provider v-bind="provider_info"/>
</template>

<script>
import Provider from "@/components/Provider.vue";
export default {
    name: "Peter Lueninghoener",
    components: {
        Provider
    },
    data() {
        return {
            provider_info: {
                name: "Peter Lueninghoener",
                img_src: "photo-coming-soon.jpg",
                undergrad: "Knox College",
                undergrad_year: 1981,
                professional_school: "University of Nebraska Medical Center",
                professional_school_year: 1988,
                biography: [ 
                    "Dr. Pete grew up on a farm near Pilger, NE and graduated from Stanton High School in 1977. He attended Knox College in Galesburg, IL and graduated with his bachelor's degree in 1981. From there he attended the University of Nebraska Medical Center at Omaha and earned his doctorate's in 1988. He completed his Family Practice residency and was Board Certified in 1991 in Casper, WY, and earned his Degree of Fellow from the American Academy of Family Practice in 2014. ",
                    "Dr. Pete arrived in O'Neill in July 1991, joining Dr. Mark Ptacek in Holt County Medical Clinic. In 1998, they became Avera Holt County Medical Clinic. Then in March 2004 he started Elkhorn Valley Family Medicine. ",
                    "His interests in medicine lie in true rural medicine; starting with obstetric care, following those infants through childhood and then as adults through to the elderly.",
                    "Dr. Pete enjoys outdoor activities including camping with his family, fishing, and hunting."
                ],
                certifications: [
                    "Advanced Cardiac Life Support (ACLS)",
                    "Advanced Cardiac Life Support (ACLS) Instructor ",
                    "Advanced Trauma Life Support (ATLS)",
                    "FMCSA Certified Medical Examiner (DOT exams)"
                ],
                acceptsNewPatients: true,
                telemedicine: true
            }
        }
    }
}
</script>

<style scoped>

</style>